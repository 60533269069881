// extracted by mini-css-extract-plugin
export var carouselContainer = "x_t0 d_w d_H d_bf d_Z";
export var carouselContainerCards = "x_t1 x_t0 d_w d_H d_bf d_Z";
export var carouselContainerSides = "x_t2 d_w d_H d_Z";
export var prevCarouselItem = "x_t3 d_w d_H d_0 d_k";
export var prevCarouselItemL = "x_t4 x_t3 d_w d_H d_0 d_k";
export var moveInFromLeft = "x_t5";
export var prevCarouselItemR = "x_t6 x_t3 d_w d_H d_0 d_k";
export var moveInFromRight = "x_t7";
export var selectedCarouselItem = "x_t8 d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "x_t9 x_t8 d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "x_vb x_t8 d_w d_H d_Z d_bf";
export var nextCarouselItem = "x_vc d_w d_H d_0 d_k";
export var nextCarouselItemL = "x_vd x_vc d_w d_H d_0 d_k";
export var nextCarouselItemR = "x_vf x_vc d_w d_H d_0 d_k";
export var arrowContainer = "x_vg d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "x_vh x_vg d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "x_vj x_vh x_vg d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "x_vk x_vg d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "x_vl";
export var nextArrowContainerHidden = "x_vm x_vk x_vg d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "x_kG d_0";
export var prevArrow = "x_vn x_kG d_0";
export var nextArrow = "x_vp x_kG d_0";
export var carouselIndicatorContainer = "x_vq d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "x_vr d_w d_bz d_bF";
export var carouselText = "x_vs d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "x_vt x_vs d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "x_vv d_b7";
export var carouselIndicator = "x_vw x_vv d_b7";
export var carouselIndicatorSelected = "x_vx x_vv d_b7";
export var arrowsContainerTopRight = "x_vy d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "x_vz d_0 d_bl d_bC";
export var arrowsContainerSides = "x_vB d_0 d_bl d_bC";
export var smallArrowsBase = "x_vC d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "x_vD x_vC d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "x_vF x_vD x_vC d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "x_vG x_vC d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "x_vH x_vG x_vC d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "x_p9";
export var iconSmall = "x_vJ";
export var multipleWrapper = "x_vK d_bC d_bF d_bf";
export var multipleImage = "x_vL d_bC";
export var sidesPrevContainer = "x_vM x_vD x_vC d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "x_vN x_vD x_vC d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";