// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_tM";
export var lbContainerInner = "w_tN";
export var movingForwards = "w_tP";
export var movingForwardsOther = "w_tQ";
export var movingBackwards = "w_tR";
export var movingBackwardsOther = "w_tS";
export var lbImage = "w_tT";
export var lbOtherImage = "w_tV";
export var prevButton = "w_tW";
export var nextButton = "w_tX";
export var closing = "w_tY";
export var appear = "w_tZ";